section.case-study-2 {
    .article {
        padding-left: 1rem;
        padding-right: 1rem;

        @include media-breakpoint-up(md) {
            padding-left: unset;
            padding-right: unset;
            margin-bottom: 50px;
        }
        @include media-breakpoint-up(lg) {
            margin-bottom: 50px;
        }

        &.first {
            margin-bottom: rem-calc(110);

            @include media-breakpoint-up(md) {
                margin-bottom: 50px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(110);
            }
        }

        

        .head {
            display: flex;
            align-items: start;
            margin-bottom: 25px;

            @include media-breakpoint-up(md) {
                align-items: flex-start;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            .author-image {
                @include max(mobile-calc(98), none);

                @include media-breakpoint-up(md) {
                    @include max(mobile-calc(98), none);
                }

                min-width: 120px;

                @include media-breakpoint-up(lg) {
                    @include size(45%, 300px);
                }
            }
            .case-info {
                padding-left: 20px;

                @include media-breakpoint-up(md) {
                    width: 55%;
                    padding-left: 1rem;
                    padding-top: 40px;
                }

                .client-logo {
                    height: auto;

                    @include media-breakpoint-up(lg) {
                        @include max(240px, 60px);
                    }

                    img {
                        max-height: 60px;
                        max-width: 70%;

                        @include media-breakpoint-up(lg) {
                            max-height: 60px;
                        }
                    }
                }
                .client-name {
                    font-size: 18px;
                    line-height: 22px;

                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 24px;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }
                    @include media-breakpoint-up(lg) {
                        line-height: 30px;
                    }
                }
            }
        }

        .description {
            @include media-breakpoint-up(lg) {
                margin-top: 30px;
            }

            .teaser {
                margin-bottom: 22px;
            }
            .extra-line {
                span {
                    @include media-breakpoint-down(md) {
                        font-size: 26px;
                        font-family: $font1;
                    }
                }
            }
            .cta {
                width: 100%;

                @include media-breakpoint-up(md) {
                    width: unset;
                }
            }
        }
    }
    .slick-slider {
        .slick-dots {
            li {
                &:after {
                    background-color: $color1;
                }
            }
        }
    }
}
