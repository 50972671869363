section.job-lists-1 {
    .col-right {
        h3,
        p,
        li {
            color: #fff;
        }
    }

    ul {
        list-style-image: url('../img/li.svg');
        li {
            padding-left: 1.5rem;
            font-family: $font2;
            margin-bottom: 1rem;
            a {
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .list-style-check {
        ul {
            list-style-image: url('../img/check.svg');
        }
    }

    @include media-breakpoint-up(lg) {
        .row:first-of-type {
            padding-top: 77px;
        }
        .row:last-of-type {
            padding-bottom: 100px;
        }
        .col-left {
            padding-right: rem-calc(90);
        }
        .col-right {
            padding-left: rem-calc(90);
        }
        &:before {
            content: '';
            @include absolute(0, auto, auto, 0, -1);
            @include size(50%, 100%);
            background-color: #f2f2f2;
        }
        &:after {
            content: '';
            @include absolute(0, 0, auto, auto, -1);
            @include size(50%, 100%);
            background-color: $color1;
        }
    }
    @include media-breakpoint-down(lg) {
        background-color: #f2f2f2;
        .col-left,
        .col-right {
            padding-top: 0px;
            padding-bottom: 36px;
        }
        .col-right {
            background-color: $color1;
            padding-top: 50px;
            padding-bottom: 36px;
        }
    }
    @include media-breakpoint-only(md) {
        .col-right {
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                @include absolute(50%, auto, auto, 50%, -1);
                @include size(100vw, 100%);
                transform: translate(-50%, -50%);
                background-color: $color1;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .col-right {
            position: relative;
            z-index: 1;            
            &:before {
                content: '';
                @include absolute(50%,auto,auto,50%,-1);
                @include size(100vw,100%);
                background-color: $color1;
                transform: translate(-50%,-50%);
            }
        }
    }
}
