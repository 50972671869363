section.video-1 {
    .video-container {
        position: relative;
        @include size(100%,auto);
        border-radius: 3rem;
        overflow: hidden;
        // margin-bottom: rem-calc(110);
        transform: translate(0,- rem-calc(110));
        filter: drop-shadow(0px 4px 70px rgba(0, 0, 0, 0.1));
        .layer {
            @include absolute(0,auto,auto,0,1);
            @include size(50%,100%);            
            padding: 2rem;
            background-color: rgba(251, 190, 106, 0.8);
            display: flex;
            align-items: center;            
        }
    }
}
