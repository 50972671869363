section.contact {
    background: url('../img/header-bg-contact-2.svg') no-repeat 42vw bottom;
    background-color: $color1;

    @include media-breakpoint-down(lg) {
        background-position-x: right;
    }

    @include media-breakpoint-down(md) {
        background-image: none;
    }

    @include media-breakpoint-down(lg) {
        .bg {
            display: none;
        }
    }

    h2,
    h3,
    p {
        color: #fff;
    }

    .bg {
        @include absolute(0, 0, auto, auto, 1);
        @include size(37.5%, 100%);
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
    }

    .contact-form {
        max-width: 555px;
    }

    form {
        .legal {
            color: #fff;
            .wpcf7-list-item-label {
                color: #fff;
            }
            .show-more {
                color: #fff;
            }
        }
        label {
            color: #fff;
        }
        select,
        input[type='text'],
        input[type='email'],
        textarea {
            width: 100%;
            color: #fff;
            border: 1px solid #fff;
            max-width: 100%;
            background: transparent;
            margin-bottom: 0;
        }

        select {
            background: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.109691 1.17054C-0.194896 0.657079 0.170675 0 0.765185 0L7.23482 0C7.82933 0 8.1949 0.657079 7.89031 1.17054L4.65483 6.6254C4.35791 7.12537 3.64143 7.12436 3.34517 6.6254L0.109691 1.17054Z' fill='white'/%3E%3C/svg%3E")
                no-repeat 93% center;
        }
        input[type='checkbox']:checked + .wpcf7-list-item-label:after {
            border-color: #fff;
        }
        option {
            color: $color1;
        }
        input::-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: white;
        }

        input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: white;
        }

        input::placeholder,
        textarea::placeholder {
            font-size: 16px;
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: 1; /* Firefox */
        }
        .wpcf7-response-output {
            margin-left: 0 !important;
            padding: 1rem;
        }
        .animated_check {
            opacity: 0;
            visibility: hidden;
        }
        .wpcf7-submit{
            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
        }
        .asterisk {
            color: #fff;
        }
    }
    .wpcf7 form.invalid .wpcf7-response-output {
        background-color: #ffb900;
    }
}
