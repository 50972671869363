section.post-title {
    min-height: 440px;
    padding-top: 84px;
    padding-bottom: 80px;

    @include media-breakpoint-down(md) {
        min-height: unset;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .h1 {
        margin-bottom: 34px;
    }
    .p {
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 25px;
        }
    }
}
