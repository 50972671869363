section.text-block-2 {    
    .bg {
        @include absolute(-4rem,auto,auto,0,-1);
        @include size(480px,auto);
        @include max(480px,none);
    }
    @include media-breakpoint-down(md) {        
        .bg {
            @include size(80vw,auto);
            left: -44px;
        }
    }
}