section.subpage-title-2 {
    padding-top: 84px;
    padding-bottom: 80px;    
    @include media-breakpoint-down(md) {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .h1 {
        margin-bottom: 34px;
    }
    .p {
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;
        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    .cta {
        position: relative;
        width: 206px;
        text-transform: none;
        border-radius: var(--btn-border-radius);
        border-color: #8B9DC1;
        padding: 0.5rem 1rem;
        font-weight: normal;
        margin-top: 10px;
        text-align: center;
        &:after {
            content: url('../img/arrow-dark.svg');
            @include absolute(150%,auto,auto,50%,1);
            transform: translate(-50%,-100%);
            transition: all 250ms ease-in-out;
            opacity: 0;
        }
        &.active {
            background-color: #8B9DC1;
            border-color: #8B9DC1;
            color: #fff;
            text-align: center;
            &:after {                
                transform: translate(-50%,0);
                opacity: 1;
            }            
        }
        &:not(.active) {
            border-color: #4B5D81;
            border-width: 1px;
        }
    }    
    @include media-breakpoint-down(lg) {
        .tabs {
            padding-left: 0rem;
            padding-right: 0rem;            
            .cta {                
                display: inline-block;
                margin-bottom: 3rem; 
                // width: 140px;
                width: auto;
                font-size: 16px;
            }
        }
        .bg {
            display: none;
        }
    }
}
