section.jobs-list {
    &:after {
        content: '';
        @include absolute(330px, auto, auto, 0, -1);
        @include size(100%, calc(100% - 300px));
        background-color: $color1;
        min-height: 300px;
    }

    .items {
        min-height: 300px;
    }

    .job-offer {
        display: none;
        &.show {
            display: flex;
        }
    }

    .select2 {
        @include media-breakpoint-down(sm) {
            width: 100% !important;
            max-width: none;
        }
    }

    .no-items {
        transform: translateY(250px);
        p {
            color: #fff;
        }
    }

    article {
        position: relative;
        border-radius: $imageBorderRadius;
        box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin-bottom: 2rem;
        display: flex;
        align-items: flex-end;
        @include size(100%, 370px);
        padding: 30px;
        .icon {
            @include absolute(20px, auto, auto, 30px, 1);
        }
        .salary {
            height: 60px;
        }
        .inner {
            @include absolute(30%, auto, auto, 30px, 1);
            max-width: calc(100% - 60px);
        }
        .cta {
            @include absolute(auto, auto, 30px, 30px, 1);
        }
    }

    @include media-breakpoint-up(lg) {
        article {
            .cta {
                width: auto;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        overflow: unset;
        article {
            .cta {
                @include size(calc(100% - 60px), auto);
            }
        }
    }
}
