section.posts-2 {

    // &:before {
    //     content: '';
    //     @include absolute(250px,auto,auto,0,-1);
    //     @include size(100%,calc(100% - 250px));
    //     background-color: $color1;
    // }

    &-title {
        font-size: 16px;
        margin-right: 70px;
    }

    @include media-breakpoint-up(lg) {
        margin-top: -150px;
        .mx-lg-n2 {
            margin-right: calc(var(--bs-gutter-x)*-.5) !important;
            margin-left: calc(var(--bs-gutter-x)*-.5) !important;
        }
    }
    @include media-breakpoint-down(sm) {
        .row.items {
            padding-right: calc(var(--bs-gutter-x)*.5);
            padding-left: calc(var(--bs-gutter-x)*.5);
        }
    }

    .card {               
        .card-inner {
            box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            background-color: #fff;
            border-radius: $imageBorderRadius;    
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            @include size(100%,520px);
            .image { 
                -webkit-mask-image: -webkit-radial-gradient(white, black);
                overflow: hidden;
                border-top-left-radius: $imageBorderRadius;    
                border-top-right-radius: $imageBorderRadius;    
                @include media-breakpoint-up(lg) {
                    border-radius: 0;
                    border-top-right-radius: $imageBorderRadius;    
                    border-bottom-right-radius: $imageBorderRadius;    
                }
            }
            h3 { 
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
        .card-image {
            background-size: cover;
            transition: all 600ms ease-in-out;
            background-position: center;
            border-top-left-radius: $imageBorderRadius;    
            border-top-right-radius: $imageBorderRadius;    
            @include media-breakpoint-up(lg) {
                border-radius: 0;
                border-top-right-radius: $imageBorderRadius;    
                border-bottom-right-radius: $imageBorderRadius;    
            }
        }
        &.first {                                    
            .info {
                padding: 2rem;                
            }
            .label {
                @include absolute(0,auto,auto,0,1);                
                @include max(200px,150px);
                background: linear-gradient(45deg, #F1665E 0%, #F1A63F 100%);
                color: #fff;
                transform: translate(-80px,-50px) rotate(-45deg);
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding: 6rem 4rem 1rem;        
            }
            .image {
                position: relative;
                overflow: hidden;  
                
                @include media-breakpoint-up(lg) {
                    border-radius: 0 $imageBorderRadius $imageBorderRadius 0;
                    .card-image {
                        will-change: transform;
                    }
                }
                @include media-breakpoint-only(md) {
                    height: 290px;
                }
                @include media-breakpoint-down(md) {
                    height: 260px;
                }
            }                 
            &:hover {
                @include media-breakpoint-up(md) {
                    .image {
                        .card-image {
                            transform: scale(1.2);
                        }
                    }
                }
    
            }
            @include media-breakpoint-up(lg) {
                .info {
                    width: 35%;
                }
                .image {
                    width: 65%;
                }
            }
            @include media-breakpoint-down(md) {
                .info {
                    width: 100%;
                    height: 50%;
                    padding: 1rem;
                }
                .image {
                    width: 100%;
                }
            }
        }
        &:not(.first) {            
            .info {
                padding: 30px 40px 40px;
                height: 50%;
                justify-content: space-between;
                align-items: flex-start;
            }
            .image {
                height: 50%;
                overflow: hidden;                
                border-radius: $imageBorderRadius $imageBorderRadius 0 0;
                .card-image {
                    will-change: transform;
                }
            }
            .card-image {
                border-radius: 0;
                border-radius: $imageBorderRadius $imageBorderRadius 0 0;
            }
            .title {
                max-width: 70%;
            }
            &:hover {
                @include media-breakpoint-up(lg) {
                    .image {
                        .card-image {
                            transform: scale(1.2);
                        }
                    }
                }
    
            }   
            @include media-breakpoint-down(md) {
                .info {                
                    height: 50%;    
                    padding: 1rem;
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
            .card-inner {
                padding-bottom: 20px;
            }
        }
    }

}
