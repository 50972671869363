section.faq-1 {
  article {
    padding-top: rem-calc(40);
    &:not(:last-of-type) {
      border-bottom: 1px dashed #8b9dc1;
    }
    &.active {
      padding-bottom: rem-calc(48);
    }
    .question {
      cursor: pointer;
      position: relative;
      padding-right: rem-calc(80);
      padding-bottom: rem-calc(40);
      .q {
        color: $color1;
        font-family: $font2;
        font-size: 22px;
        font-weight: 700;
      }

      &:after {
        content: "";
        @include absolute(0, 0, auto, auto, 1);
        @include size(16px, 16px);
        transform: translate(0, 50%) rotate(45deg);
        border-bottom: 3px solid $color1;
        border-right: 3px solid $color1;
      }

      &.active {
        &:after {
          border: 0;
          border-top: 3px solid $color1;
          border-left: 3px solid $color1;
          transform: translateY(100%) rotate(45deg);
        }
      }
    }
    .answer {
      overflow: hidden;
      display: none;
    }
  }
}
