section.personel-2 {
  .p {
    @include media-breakpoint-down(md) {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .cta {
    width: 70%;
    padding-left: 30px;
    padding-right: 30px;

    @include media-breakpoint-down(xl) {
      width: 80%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .img {
    @include media-breakpoint-down(md) {
      @include max(mobile-calc(98), none);
    }
  }
}
