section.logos-1 {
    background-color: $color1;
    h1,
    h2 {
        color: #fff;
    }    
    @include media-breakpoint-down(md) {
        .slick-slider {
            padding-bottom: 4rem;
        }
        .slider-dots .slick-slide {
            margin-bottom: 0rem;
        }
    }
}
