section.case-study-3 {
    padding-top: 60px;
    padding-bottom: 100px;
    background-color: #f2f2f2;

    &:before {
        content: '';
        @include absolute(0, 0, auto, auto, 0);
        @include size(50%, 100%);
        background-color: $color3;
    }
    .item {
        .num-col {
            padding-right: 1rem;
            max-width: 55%;

            // @include media-breakpoint-up(xl) {
            //     width: 100%;
            //     max-width: 55%;
            // }

            .number {
                color: $color1;
                font-size: 85px;
                line-height: 70px;
                font-family: $font1;
                margin-bottom: 0;
                font-weight: 800;
                word-break: keep-all;
            }
            @include media-breakpoint-up(lg) {
                text-align: right;
            }            
        }
        p {
            strong {
                font-family: $font1;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .item {
            td {
                padding-bottom: 50px;
            }
        }        
    }

    @include media-breakpoint-down(lg) {
        &:before {
            display: none;
        }
    }

    
    @include media-breakpoint-only(md) {
        .item {
            margin-bottom: 30px;
            display: block;
            td {
                display: block;
            }
            .num-col {
                width: 100%;
                max-width: 100%;
            }
        }
        .col2 {
            position: relative;
            padding-top: 3rem;
            padding-bottom: 3rem;
            position: relative;
            z-index: 1;
            &:after {
                content: '';
                @include absolute(0, auto, auto, 50%, -1);
                @include size(100vw, 100%);
                transform: translate(-50%, 0);
                background-color: $color3;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .item {
            margin-bottom: 30px;
            display: block;
            td {
                display: block;
            }
            .num-col {
                width: 100%;
                max-width: 100%;
                .number {
                    font-size: 60px;
                }
            }
        }        
        .container {
            .row {
                .col1 {
                    img {
                        @include max(mobile-calc(98), none);
                    }
                }
                .col2 {
                    padding-top: rem-calc(53);
                    padding-bottom: rem-calc(50);
                    background-color: $color3;
                }
            }
        }
        .head {
            p,
            .p2 {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}
