section.personel-1 {
    .subtitle {
        font-size: 30px;
        line-height: 70px;

        @include media-breakpoint-down(lg) {
            font-size: 20px;
            line-height: 20px;
        }
    }

    .photo {
        @include media-breakpoint-down(lg) {
            width: 30%;
        }
    }
}
