section.jobs-list-2 {
    
    &:after {
        content: '';
        @include absolute(330px,auto,auto,0,-1);
        @include size(100%,calc(100% - 500px));
        background-color: $color1;
        min-height: 300px;
    }

    .items {
        min-height: 300px;
    }

    .job-offer {
        display: none;
        &.show {
            display: flex;
        }
    }

    .no-items {
        transform: translateY(250px);
        p {
            color: #fff;
        }
    }

    article {   
        position: relative;
        border-radius: $imageBorderRadius;
        box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin-bottom: 2rem;    
        display: flex;
        align-items: flex-end;
        @include size(100%,370px);        
        padding: 30px;           
        .icon {
            @include absolute(20px,auto,auto,30px,1);
        }
    }

    @include media-breakpoint-down(sm) {
        overflow: unset;
    }
}
