section.columns-1 {
    @include media-breakpoint-up(lg) {
        &:not(.no-bg) {
            &:before {
                content: '';
                @include absolute(0, auto, auto, 0, -1);
                @include size(50%, 100%);
                background-color: #f2f2f2;
            }
            &:after {
                content: '';
                @include absolute(0, 0, auto, auto, -1);
                @include size(50%, 100%);
                background-color: $color1;
            }
        }
    }
    .col-left,
    .col-right {
        padding-top: 77px;
        padding-bottom: 117px;

        @include media-breakpoint-down(md) {
            padding-top: 25px;
            padding-bottom: 50px;
        }
    }

    .col-right {
        padding-left: rem-calc(90);
        
        @include media-breakpoint-down(md) {
            padding-left: calc(var(--bs-gutter-x)*.5);
        }

        h3,
        p,
        li {
            color: #fff;
        }
    }

    ul {
        list-style-image: url('../img/li.svg');
        li {
            padding-left: 1.5rem;
            font-family: $font2;
            margin-bottom: 1rem;
            color: $color1;
            font-size: 18px;
            line-height: 30px;

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 26px;
                padding-left: 0.5rem;
            }
        }
    }
}
