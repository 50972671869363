section.slideshow-full-1 {
    .slick-slide {
        img {
            margin: 0 auto;
        }
    }
    .slider-nav {
        @include absolute(80%,auto,auto,50%,1);
        transform: translate(-50%,0);
        opacity: 1;
        .slick-dots {
            @include absolute(auto, auto, 0, 0, 1);
            list-style: none;
            padding-left: 1rem;
            li {
                position: relative;
                display: inline-block;
                @include size(34px, 20px);                 
                margin-right: 1rem;            
                cursor: pointer;
                transition: all 250ms ease-in-out;          
                button {
                    visibility: hidden;
                }
                &:after {
                    @include absolute(auto,auto,0,0,1);
                    content: '';
                    display: flex;
                    @include size(34px, 4px);
                    background-color: #fff;
                    border-radius: 100px;
                    // transform: translateY(4px);
                    opacity: 0.5;         
                    transition: all 250ms ease-in-out;          
                }
                &.slick-active {                                
                    @include size(93px, 20px);
                    &:after {                    
                        @include size(93px, 8px);
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
                &:only-child {
                    display: none;
                }
                
                
            }
            @include media-breakpoint-down(md) {
                left: 0;
                padding-left: 1rem;
            }
        }  
        ._slick-dots {
            list-style: none;
            padding-left: 0;
            li {
                display: inline-block;            
                @include size(30px,4px);
                transform: translateY(4px);
                transition: all 250ms ease-in-out;
                background-color: #ffffff;
                border-radius: 100px;
                margin-right: 1rem;
                cursor: pointer;
                button {                
                    visibility: hidden;
                }
                &.slick-active {
                    @include size(60px,8px);
                    transform: translateY(0);
                }
                &:only-child {
                    display: none;
                }
            }        
        }    
    } 
    .slick-arrow {
        @include absolute(50%, auto, auto, 50%, 1);
        @include size(29px, 29px);
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        cursor: pointer;
        &.slick-prev {
            transform: translate(calc(#{map-get($grid-breakpoints, 'xl')} / -2), -50%) rotate(-135deg);
        }
        &.slick-next {
            transform: translate(calc(#{map-get($grid-breakpoints, 'xl')} / 2), -50%) rotate(45deg);
        }
    }
    @include media-breakpoint-down(lg) {
        overflow-x: hidden;
    }
    @include media-breakpoint-down(sm) {
        .slick-slide {
            margin-bottom: 0;
        }
    }
}