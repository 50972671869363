body.noScroll {
    overflow: hidden;
}

.off {
    pointer-events: none;
}

.overflow-auto {
    overflow: auto;
}

@function rem-calc($size) {
    $remSize: calc($size / 16);
    @return #{$remSize}rem;
}

@function mobile-calc($size) {
    $return: calc($size / 375 * 100);
    @return #{$return}vw;
}

@function vw-calc($px-vw, $base-vw: 1920px) {
    @return calc(($px-vw * 100vw) / $base-vw);
}


@keyframes stretch {
    0% {
        transform: scale(1);        
    }
    50% {
        transform: scale(1.15);        
    }
    100% {
        transform: scale(1);        
    }    
}