section.hero {
    position: relative;        
    overflow-x: hidden;    
    height: auto !important;
    .container {
        padding-top: 0;
        @include absolute(50%,auto,auto,50%,1);
        transform: translate(-50%,-50%);
        transition: all 250ms ease-in-out;
        transition-delay: 250ms;
        opacity: 0;        
        p,h1,h2,h3 {
            color: #fff;
        }        
    }
    .slider-nav {
        @include absolute(80%,auto,auto,50%,1);
        transform: translate(-50%,0);
        opacity: 1;
    }  
    .slick-dots {
        @include absolute(auto, auto, 0, 0, 1);
        list-style: none;
        padding-left: 1rem;
        li {
            position: relative;
            display: inline-block;
            @include size(34px, 20px);                 
            margin-right: 1rem;            
            cursor: pointer;
            transition: all 250ms ease-in-out;          
            button {
                visibility: hidden;
            }
            &:after {
                @include absolute(auto,auto,0,0,1);
                content: '';
                display: flex;
                @include size(34px, 4px);
                background-color: #fff;
                border-radius: 100px;
                // transform: translateY(4px);
                opacity: 0.5;         
                transition: all 250ms ease-in-out;          
            }
            &.slick-active {                                
                @include size(93px, 20px);
                &:after {                    
                    @include size(93px, 8px);
                    transform: translateY(0);
                    opacity: 1;
                }
            }
            &:only-child {
                display: none;
            }
            
        }
        @include media-breakpoint-down(md) {
            left: 0;
            padding-left: 1rem;
        }
    }  
    

    .slick-slider {
        max-height: 100vh;
        .slick-slide {
            position: relative;
            max-height: 100vh;
            overflow: hidden;
            .bg {
                margin: 0 auto;
            }
            .video {
                @include size(100%,auto);
                position: relative;
                iframe {
                    @include size(100%,auto);
                }
            }
            video {
                @include size(100%,auto);
                position: relative;
            }
            &.slick-current {
                .container {
                    opacity: 1;
                }
            }
            &[data-overlay="true"] {
                &:before {
                    content: '';
                    @include absolute(0,auto,auto,0,1);
                    @include size(100%,100%);
                    background-color: #232E43;
                    opacity: 0.8;
                }
                &[data-overlay-opacity="60"] {
                    &:before {
                        opacity: 0.6;
                    }
                }
                &[data-overlay-opacity="70"] {
                    &:before {
                        opacity: 0.7;
                    }
                }
                &[data-overlay-opacity="80"] {
                    &:before {
                        opacity: 0.8;
                    }
                }
                &[data-overlay-opacity="90"] {
                    &:before {
                        opacity: 0.9;
                    }
                }
                &[data-overlay-opacity="100"] {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }              
    }    
    
    @media (min-width: 1921px) {
        .slick-slider {
            max-height: 1000px;
            .slick-slide {
                max-height: 1000px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .bg {
            width: 100vw;
        }
    }
}