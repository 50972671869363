section.info-boxes-1 {
    
    article {   
        margin-bottom: 4rem;    
        box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
        border-radius: $imageBorderRadius;
        overflow: hidden;
        background-color: #fff;
        height: 540px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        will-change: transform;
        .image {
            position: relative;
            @include size(100%,300px);            
            overflow: hidden;            
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            .bg {
                @include absolute(0,auto,auto,0,1);                
                @include size(100%,100%);                              
                transition: all 600ms ease-in-out;
                background-size: cover;
                background-position: center top;
            }            
        }
        .content {            
            padding: rem-calc(40);   
            height: calc(100% - 300px);
            min-height: 240px;        
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            .title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;            
            }
            
        }       

        &:hover {
            .image {
                .bg {
                    transform: scale(1.2);
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        article {
            height: 420px;
            margin-bottom: 0;
            .image {
                height: 234px;
                img {
                    @include size(auto,100%);
                }
            }
            .content {
                min-height: 185px;
                height: auto;
                padding: 1.37rem 1.25rem 1.9rem 1.25rem;
            }
        }
        .container {
            overflow-x: scroll;
            overflow-y: hidden;            
            padding-top: 3rem;
            padding-bottom: 3rem;
            .row {
                width: 150vw;
            }
        }
    }
}
