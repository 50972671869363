section.tech-stack-1 {

    @include media-breakpoint-up(lg) {

        .items {
            
            &:not(:last-of-type) {
                
                .group {
                    border-bottom: 1px solid $color1;                
                }

            }

        }

    }

    .group {
       
        padding: 40px 0;

        .group-name {
            color: $color1;
            text-align: left;
            font-family: $font1;    
            font-weight: 600;    
        }

        .column {
            display: inline-block;
            width: calc(25% - 20px);
            margin: 0 8px;
            vertical-align: top;
            .icons-container {
                height: 50px;
            }
        }
        
        .box {
            @include size(100%,206px);
            background: #fff;
            box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
            border-radius: var(--btn-border-radius);
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 50px;
            div {
                display: flex;
                justify-content: flex-start;
                img {
                    margin-right: 0.75rem;                    
                    max-width: 28%;                    
                }
            }
            p {
                font-weight: 600;
            }
        }

        .columns-group {
            display: inline;
        }

    }

    @include media-breakpoint-down(md) {
        overflow-x: hidden;
        .items {
            
            .group {
                padding: 0;
            }
            .column {
                &:first-of-type {
                    width: 100%;
                }
            }

            .columns-group {
                // overflow-x: auto;
                // display: flex;
                overflow: unset;
                display: block;
                padding: 0 .75rem 2rem;
                .column {
                    width: auto;
                    .box {
                        @include size(45vw,160px);                        
                        margin: 1rem 0;
                        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        div {
                            display: flex;
                            justify-content: flex-start;
                            img {
                                margin-right: 0.75rem;
                                max-width: 25%;
                            }
                        }
                    }
                }
            }
        }

        .slick-slider {
            padding-bottom: 5rem !important;
            .slick-track {
                overflow: unset;
            }
            .slick-list {
                overflow: unset;
            }
            .slick-slide {
                overflow: unset;
            }
        }

    }
    @include media-breakpoint-down(sm) {
        .group {
            .box {
                padding: 30px 20px;
            }
        }
        .slick-slider {
            .slick-dots {
                li {
                    &:after {
                        background-color: $color1;
                    }
                }
            }
        }
    }

}
