section.medium-blocks-1 {
    // &:after {
    //     content: '';
    //     @include absolute(330px,auto,auto,0,-1);
    //     @include size(100%,calc(100% - 500px));
    //     background-color: $color1;
    // }

    article {
        border-radius: $imageBorderRadius;
        box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin-bottom: 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 60px 20px 30px 20px;
        min-height: 320px;
        
        @include media-breakpoint-up(lg) {
            min-height: 75px;
            padding: 40px 30px 0px 40px;
        }

        .img {
            height: 64px;

            @include media-breakpoint-up(lg) {
                height: 72px;
            }

            img {
                max-width: 72px;
            }
        }

        .h4 {
            @include media-breakpoint-down(lg) {
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
            }

            br {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }
}
