body {
    &.single-case-study {
        .menu-item-1194 {
            @extend .current-menu-item;
        }
    }
    &.single-blog-post {
        .menu-item-1195 {
            @extend .current-menu-item;
        }
    }
    &.mobile-nav {
        overflow: hidden;
        header {
            .logo-main {
                // filter: brightness(100);
            }
            &:before {
                background: transparent;
            }
            background: transparent;
            .nav-container {
                transform: translate(0, 0);
                opacity: 1;
                visibility: visible;

                ul {
                    width: 100%;
                    max-width: calc(map-get($grid-breakpoints, 'md'));
                    margin: 1rem auto auto;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    @include media-breakpoint-only(lg) {
                        max-width: calc(map-get($grid-breakpoints, 'lg'));
                    }
                    .sub-menu {
                        li {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .cta {
                opacity: 0;
                pointer-events: none;
                z-index: -1;
            }
        }
    }
    &.home {
        @include media-breakpoint-up(xl) {
            // touch-action: none;
        }
        header:not(.scrolled) {
            .hamburger {
                .line {
                    stroke: #fff;
                }
            }
            .nav-top {
                li {
                    a {
                        @include media-breakpoint-up(xl) {
                            color: #fff;
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }
                    &.current-menu-item {
                        a {
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .lang-switch {
                a {
                    &:after {
                        background-color: #fff;
                    }
                }
            }
            .logo-main {
                filter: brightness(100);
            }
            .hamburger {
                .line {
                    // stroke: #fff;
                }
            }
            .lang-switch {
                @include media-breakpoint-up(lg) {
                    a {
                        color: #fff;
                    }
                }
            }
        }
        &.mobile-nav {
            header {
                .hamburger {
                    .line {
                        stroke: $color1;
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.page-realizations {
            @include media-breakpoint-up(md) {
                background: url('../img/header-bg-realizations.svg') no-repeat right top;
            }
        }
        &.page-case-study {
            @include media-breakpoint-up(md) {
                background: url('../img/header-bg-case-study.svg') no-repeat 77% 220px;
                background-size: vw-calc(598px, 1920px) vw-calc(675px, 1920px);
            }
        }
        &.page-about-us {
            @include media-breakpoint-up(md) {
                background: url('../img/header-bg-about-us.svg') no-repeat 45% 220px;
                background-size: vw-calc(645px, 1920px) vw-calc(560px, 1920px);
            }
        }
        &.page-offer-single {
            @include media-breakpoint-up(md) {
                background: url(../img/header-bg-offer-single.svg) no-repeat 105% -80px;
                background-size: 31.1458333333vw 35.15625vw;
            }
        }
        &.page-blog {
            @include media-breakpoint-up(md) {
                // TODO
                // background: url('../img/tmp/6.0_Blog_Desktop_1920.jpg') no-repeat center top;

                background: url('../img/header-bg-blog.svg') no-repeat 106% 380px;
                background-size: vw-calc(1001px, 1920px) vw-calc(1007px, 1920px);
            }
        }
        &.page-career {
            background: url('../img/header-bg-career.svg') no-repeat 35% 190px;
        }
        &.page-offer {
            background: url('../img/header-bg-career.svg') no-repeat 35% 190px;
        }
        &.page-career-offer {
            background: url('../img/header-bg-career-offer.svg') no-repeat 105% 190px;
        }
        &.page-contact {
            background: url('../img/header-bg-contact.svg') no-repeat right top;
        }
    }
    &.mobile-nav {
        // .aside-social {
        //     transform: translate(0, -50%);
        // }
    }
}

header {
    font-family: $font2;
    @include fixed(0, auto, auto, 0, 10);
    transform: translate(0, 0);
    @include size(100%, auto);
    @include max(100vw, none);
    transition: all 250ms ease-in-out;

    top: 0;
    padding-top: 14px;
    padding-bottom: 14px;

    @include media-breakpoint-up(xl) {
        padding-top: 55px;
        padding-bottom: 35px;
    }
    @include media-breakpoint-down(md) {
        .container {
            .row {
                padding: 0 8px;
            }
        }
    }

    .logo-main {
        @include max(mobile-calc(80), none);

        @include media-breakpoint-up(xl) {
            max-width: unset;
        }
    }

    .hamburger {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transition: transform 400ms;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        z-index: 100;

        .line {
            fill: none;
            transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
            stroke: $color1;
            stroke-width: 5.5;
            stroke-linecap: round;
            &.top {
                stroke-dasharray: 40 121;
            }
            &.bottom {
                stroke-dasharray: 40 121;
            }
        }
        &.active {
            transform: rotate(45deg);
            .line {
                // stroke: #fff;
                stroke: $color1;
                &.top {
                    stroke-dashoffset: -68px;
                }
                &.bottom {
                    stroke-dashoffset: -68px;
                }
            }
        }
    }

    .menu-menu-glowne-pl-container {
        display: flex;
        align-items: center;
        margin-right: 2rem;
    }

    &:before {
        content: '';
        @include size(100%, 100%);
        @include absolute(50%, auto, auto, 50%, -1);
        transform: translate(-50%, -50%);
        transition: all 250ms ease-in-out;
        opacity: 0;
        background-color: #fff;
    }

    &.scrolled {
        background-color: #fff;
        @include media-breakpoint-up(lg) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &:before {
            opacity: 1;
        }
    }

    .nav-container {
        z-index: 99;
        overflow-y: auto;
        // padding-top: 80px;
        padding-top: 0px;
        padding-bottom: 80px;

        @include media-breakpoint-down(xl) {
            opacity: 0;
            @include fixed(0, 0, auto, auto, -1);
            @include size(85vw, 100vh);
            @include max(360px, 100vh);
            background: #fff;
            transition: all 350ms ease-in-out;
            display: flex;
            align-items: center;
            transform: translate(100%, 0);
        }
        @include media-breakpoint-up(xl) {
            overflow: visible;
            padding-top: unset;
            padding-bottom: unset;
        }
        @include media-breakpoint-only(lg) {
            max-width: 560px;
            padding-top: 2rem;
        }

        @include media-breakpoint-down(lg) {
            display: block;
            .social-media {
                list-style: none;
                width: 100%;
                display: flex;
                justify-content: space-around;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                li {
                    display: inline-block;
                }
            }
            .lang-switch {
                li {
                    margin: 0 0.8rem;
                }
            }
        }

        .nav-top {
            display: block;
            list-style: none;
            margin-bottom: 0;
            padding-left: 1rem;

            @include media-breakpoint-up(xl) {
                padding-left: 0;
            }

            li {
                position: relative;
                display: block;
                margin-left: 0;
                margin: 0 0.8rem;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;

                @include media-breakpoint-up(xl) {
                    display: inline-block;
                    position: relative;
                }
                &:hover {
                    &:not(.menu-item-has-children) a:after {
                        @include media-breakpoint-up(xl) {
                            width: 100%;
                        }
                    }

                    .sub-menu {
                        @include media-breakpoint-up(xl) {
                            opacity: 1;
                            visibility: visible;
                            transform: translate(-50%, 0);
                        }
                    }
                }
                &:not(:last-of-type) {
                    margin-bottom: 1rem;

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 0;
                    }
                }

                a {
                    display: block;
                    text-transform: none;
                    color: inherit;
                    text-decoration: none;
                    font-family: $font1;
                    color: $color1;
                    font-size: 14px;
                    line-height: 120%;
                    font-weight: bolder;
                    position: relative;
                    font-size: 1.4rem;

                    @include media-breakpoint-up(xl) {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-family: $font2;
                    }

                    &::after {
                        content: '';
                        @include absolute(115%, auto, auto, 0, 1);
                        @include size(0, 2px);
                        background-color: $color1;
                        transition: all 200ms ease-in-out;
                    }

                    &:hover {
                        text-decoration: none;

                        &:not(.menu-item-has-children) {
                            a:after {
                                @include media-breakpoint-up(xl) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .sub-menu {
                    li {
                        a {
                            font-size: 1.1rem;

                            @include media-breakpoint-up(xl) {
                                font-size: 14px;
                            }
                        }
                    }
                }

                &.current-menu-item {
                    a {
                        &:after {
                            content: '';
                            @include absolute(110%, auto, auto, 0, 1);
                            @include size(100%, 2px);
                            background-color: $color1;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }
                }
                @include media-breakpoint-up(xl) {
                    .sub-menu {
                        @include absolute(100%, auto, auto, 50%, 1);
                        transition: all 200ms ease-in-out;
                        @include size(auto, auto);
                        @include min(240px, 0);
                        color: $color1;
                        padding-left: 0;
                        opacity: 0;
                        visibility: hidden;
                        transform: translate(-50%, 5%);
                        background-color: #fff;
                        border-radius: var(--btn-border-radius);
                        box-shadow: 0 4px 70px #0000001a;
                        &:before {
                            content: '';
                            @include absolute(0, auto, auto, 50%, -1);
                            @include size(10px, 10px);
                            background-color: #fff;
                            transform: translate(-50%, -50%) rotate(45deg);
                        }
                        li {
                            padding: 0.6rem 1.25rem 0.4rem;
                            color: $color1;
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                            min-height: 36px;
                            a {
                                color: $color1 !important;
                                display: block;
                                width: 100%;
                                line-height: 100%;
                                font-weight: 400;
                                text-transform: none;

                                &:after {
                                    display: none !important;
                                }
                            }
                            &:first-of-type {
                                border-top-left-radius: var(--btn-border-radius);
                                border-top-right-radius: var(--btn-border-radius);
                            }
                            &:last-of-type {
                                border-bottom-left-radius: var(--btn-border-radius);
                                border-bottom-right-radius: var(--btn-border-radius);
                            }
                            &:not(:last-of-type) {
                                border-bottom: 1px solid #e2e5eb;
                            }
                            &:hover {
                                background-color: $color4;
                                a {
                                    color: #fff !important;
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cta {
        font-size: 11px;
        padding: 0.61rem 1.1rem;

        @include media-breakpoint-up(md) {
            font-size: 14px;
            padding: 0.8rem 1.9rem;
        }
        @include media-breakpoint-up(lg) {
            margin: 0 0.8rem;
            &:hover {
                background: transparent;
            }
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .lang-switch {
        text-transform: uppercase;
        color: inherit;
        text-decoration: none;
        color: $color1;
        font-size: 14px;
        font-weight: bolder;
        position: relative;
        list-style: none;
        a {
            color: $color1;
            text-decoration: none;
            position: relative;
            &:after {
                content: '';
                @include absolute(115%, auto, auto, 0, 1);
                @include size(0, 2px);
                background-color: $color1;
                transition: all 200ms ease-in-out;
            }
            &:hover {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    &.dark {
        .nav-top {
            li {
                a {
                    color: #fff;
                }
                &.current-menu-item {
                    a {
                        &:after {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
        .logo-main {
            filter: brightness(100);
        }
        .hamburger {
            .line {
                stroke: #fff;
            }
        }
    }
}

.breadcrumbs {
    @include media-breakpoint-down(sm) {
        .container {
            overflow-x: visible;
            max-width: none;
            overflow-x: scroll;
            overflow-y: hidden;
            margin: 0 20px;
            .row {
                .col-12 {
                    width: auto;
                    max-width: none;
                    padding: 0;
                }
            }
        }
    }
    ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        margin: 1px 0;

        li {
            margin-right: 3px;
            display: inline-block;
            color: $color1;
            font-family: $font2;

            &.separator {
                top: 2px;
                position: relative;
                color: $color5;
            }

            a,
            .bread-current {
                color: $color1;
                text-decoration: none;
                font-family: $font2;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                br {
                    content: '';
                    &:after {
                        content: ' ';
                    }
                }
            }
        }
    }
}
