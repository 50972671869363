.foot {
    padding-top: 7rem;
    padding-bottom: 8rem;
    z-index: 5;
    position: relative;

    border-top-right-radius: 3rem;
    margin-top: -3rem;

    p,
    h2,
    h3,
    li,
    a {
        color: #ffffff;
    }

    .nav-foot {
        list-style: none;
        margin: 5rem 0;
        li {
            display: inline-block;            
            @include media-breakpoint-up(lg) {
                margin-bottom: 0.2rem;
                a {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 0.5rem;              
                }
                a:after {
                    content: '';
                    @include absolute(110%, auto, auto, 0, 1);
                    @include size(0, 2px);
                    background-color: #fff;
                    transition: all 200ms ease-in-out;
                }
                &:hover {
                    a:after {
                        width: 100%;
                    }
                }
            }
        }
    }
    .copyrights {
        p {
            font-size: 14px;
            font-weight: 400;
            a {
                font-weight: 700;
            }
        }
    }

    .quick-contact {
        display: inline-flex;
        font-size: 35px;
        line-height: 65px;
        font-weight: 800;
        text-decoration: none;
        align-items: center;

        @include media-breakpoint-down(sm) {
            font-size: 25px;
            line-height: 55px;
        }

        &:hover {
            color: #fff;
        }
        div {
            display: inline-block;
            width: 40px;
            text-align: center;
            margin-right: 1rem;
        }
    }

    .scroll-down {
        display: none;
    }

    .nav-footer {
        ul {
            padding-left: 0;
            li {
                width: 25%;
                display: inline-block;
                text-align: left;
                a {
                    font-family: $font2;
                    text-decoration: none;
                    font-size: 18px;
                    line-height: 120%;
                    margin-bottom: 13px;
                }
                .sub-menu {
                    display: none;
                }
            }
        }
    }

    .footer-form {
        form {
            color: #fff;
            p,
            span {
                color: #fff;
            }
            input[type='email'] {
                border-radius: 0;
                border: 0;
                border-bottom: 1px solid #fff;
                padding-left: 0;
                color: #fff;
            }
            input::placeholder {
                color: white;
            }
            .asterisk {
                display: none;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .footer-title {
            font-size: 28px;
            line-height: 40px;
        }
        .row {
            &:last-of-type {
                margin-top: 9rem;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 60px;
        padding-bottom: 40px;
        .footer-title {
            font-size: 20px;
            line-height: 30px;
        }
        .nav-foot {
            margin: 2rem 0;
        }
        .nav-footer {
            ul {
                padding-left: 0;
                li {
                    width: 32%;
                    margin-bottom: 1rem;
                }
            }
        }
        .copyrights {
            text-align: center;
        }
    }
}

body.home .foot {
    margin-top: 0 !important;
}
