section.video-1 {
    overflow: unset;
    .video-container {
        position: relative;
        @include size(100%,auto);
        border-radius: $imageBorderRadius;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;        
        filter: drop-shadow(0px 4px 70px rgba(0, 0, 0, 0.1));
        .layer {
            @include absolute(0,auto,auto,0,1);
            @include size(50%,100%);            
            padding: 2rem;
            background-color: rgba(251, 190, 106, 0.8);
            display: flex;
            align-items: center;            
        }        
        video {
            @include absolute(0,auto,auto,0,-1);
            @include size(100%,auto);            
        }
        &.play {
            video {
                z-index: 10;
            }
        }
        .play-btn {
            @include absolute(50%,auto,auto,50%,2);
            transform: translate(-50%,-50%);
            cursor: pointer;            
        }
    }
    @include media-breakpoint-up(lg) {
        .poster {
            transition: all 250ms ease-in-out;
        }
        .video-container:hover {
            .poster {
                transform: scale(1.015);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        h2 {
            line-height: 100%;
            font-size: 20px;
        }
        .play-btn {
            @include size(34px,34px);
        }
    }
}