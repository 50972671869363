section.columns-quote {
    .quote {
        display: block;
        padding: 40px 0;
        width: 100%;
        margin: 0 auto 150px;
        max-width: 470px;
        position: relative;
        font-size: 40px;
        line-height: 60px;

        @include media-breakpoint-down(md) {
            margin: 0 auto 35px;
            font-size: 25px;
            line-height: 35px;
        }

        &:before,
        &:after {
            content: '';
            @include absolute(0, auto, auto, 0, 1);
            @include size(100%, 1px);
            @include max(320px, none);
            background-color: $color1;
        }
        &:after {
            @include absolute(auto, auto, 0, 0, 1);
        }
    }

    @include media-breakpoint-up(lg) {
        &:not(.no-bg) {
            &:before {
                content: '';
                @include absolute(0, auto, auto, 0, -1);
                @include size(50%, 100%);
                background-color: #f2f2f2;
            }
            &:after {
                content: '';
                @include absolute(0, 0, auto, auto, -1);
                @include size(50%, 100%);
                background-color: $color1;
            }
        }
    }
}
