section.offer-1 {
    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a {
            text-decoration: none;
            p {
                transition: all 250ms ease-in-out;
            }
            img {
                transition: all 250ms ease-in-out;
            }
            &:hover {
                p {
                    color: $color2;
                }
                @include media-breakpoint-up(md) {
                    img {
                        transform: translateY(-5px);
                    }
                }
            }
        }
    }
    &.background-dark {
        background-color: $color1;
        h2,
        h3,
        p {
            color: #fff;
        }
    }
    @include media-breakpoint-up(lg) {
        .item {
            div {
                &:nth-of-type(1) {
                    height: 80px;
                }
            }
        }
    }
    @include media-breakpoint-only(md) {
        .item {
            margin-bottom: 40px;
            strong {
                font-weight: 700;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .item {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            a {
                display: flex;
                align-items: center;
            }

            .icon-box {
                min-height: 60px;
            }

            div {
                &:nth-of-type(1) {
                    @include size(55px, 40px);
                    text-align: center;
                    img {
                        height: 100%;
                    }
                }
                &:nth-of-type(2) {
                    margin-left: 0.7rem;
                    p {
                        font-size: 16px;
                        line-height: 140%;
                        display: inline-block;
                        br {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}
