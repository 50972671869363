section.columns-2 {
    @include media-breakpoint-up(lg) {
        &:not(.no-bg) {
            &:before {
                content: '';
                @include absolute(0, auto, auto, 0, -1);
                @include size(50%, 100%);
                background-color: #f2f2f2;
            }
            &:after {
                content: '';
                @include absolute(0, 0, auto, auto, -1);
                @include size(50%, 100%);
                background-color: $color1;
            }
        }
    }

}
