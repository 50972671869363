section.tabs-1 {
    .tabs-list {
        position: relative;
        list-style: none;
        display: flex;
        // justify-content: space-evenly;
        padding-left: 0;
        margin-top: 30px;
        margin-bottom: 190px;
        margin-top: 40px;

        li {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            width: 16.5%;
            cursor: pointer;

            &:not(:last-of-type) {
                &:after {
                    content: '';
                    @include absolute(50%, auto, auto, 14px, 0);
                    transform: translate(0, -50%);
                    // @include size(82.5%,1px);
                    @include size(100%, 3px);
                    background-color: $color5;
                }
            }

            .point {
                z-index: 2;
                position: relative;
                display: block;
                border-radius: 100%;
                background-color: #fff;
                @include size(40px, 40px);
                border: 3px solid $color5;
                &:after {
                    content: '';
                    @include absolute(50%, auto, auto, 50%, 1);
                    transform: translate(-50%, -50%);
                    @include size(0, 0);
                    opacity: 0;
                    background-color: $color2;
                    border-radius: 100%;
                    transition: all 250ms ease-in-out;
                }
            }

            label {
                @include absolute(3.5rem, auto, auto, 0, 1);
                width: 60%;
                transition: all 250ms ease-in-out;
                cursor: pointer;
                font-weight: bold;
                font-size: 18px;
                width: 90%;
                color: $color1;
            }

            .number {
                @include absolute(-2.75rem, auto, auto, 5px, 1);
                cursor: pointer;
                font-weight: bold;
                color: $color1;
                font-size: 24px;
            }

            .arrow {
                @include absolute(9rem, auto, auto, 0, 1);
                @include size(15px, auto);
                transform: translate(0, -100%);
                transition: all 200ms ease-in-out;
                opacity: 0;
            }

            &.current {
                .arrow {
                    transform: translate(0, 0);
                    opacity: 1;
                }
                .point {
                    &:after {
                        @include size(24px, 24px);
                        opacity: 1;
                    }
                }
                label {
                    color: $color2;
                }
            }

            @include media-breakpoint-down(lg) {
                width: 160px;
                label {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }
}
