section.video-embed {
    overflow: unset;
    .video-container {
        position: relative;
        @include size(100%,auto);
        padding-top:56.25%;
        border-radius: $imageBorderRadius;
        overflow: hidden;
        // transform: translate(0,- rem-calc(110));
        filter: drop-shadow(0px 4px 70px rgba(0, 0, 0, 0.1));      
        iframe {            
            @include size(100%,100%);
            @include absolute(0,auto,auto,0,1);
        }
    }   
}
