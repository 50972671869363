section.icons-2 {
    &.background-dark {
        background-color: $color1;
        h2,
        h3,
        h4,
        p {
            color: #fff;
            z-index: 1;
        }
    }
    &.background-light {
        .slick-dots {
            li {
                background-color: $color1;
            }
        }
    }

    .p1 {
        @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 20px;
            // display: inline;
        }
    }
    .p2 {
        @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 20px;
            display: inline-flex;
        }
    }
    .item {
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        } 
    }    

    @include media-breakpoint-up(lg) {
        .row.icons {
            // width: 80%;
            margin: 0 auto;
            .icon {
                height: 80px;
                align-items: center;
                display: flex;
            }
            .text {
                max-width: 80%;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .icons {
            .item {
                display: flex;
                margin-bottom: 2rem;
                align-items: center;
                div {
                    &:first-of-type {
                        display: flex;
                        align-items: center;
                        width: 55px;
                        img {
                            width: 50px;
                        }
                    }
                    &:nth-of-type(2) {
                        padding-left: 1rem;                        
                    }
                }
            }
        }
    }
}
