section.text-block-5 {
    .section-title {
        font-size: 40px;
        line-height: 120%;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }
}
