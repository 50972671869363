section.posts-1 {

    // &:before {
    //     content: '';
    //     @include absolute(250px,auto,auto,0,-1);
    //     @include size(100%,calc(100% - 550px));
    //     background-color: $color1;
    //     @include media-breakpoint-down(sm) {
    //         @include size(100%,calc(100% - 550px));
    //     }
    // }

    &-title {
        font-size: 16px;
        margin-right: 70px;
    }

    @include media-breakpoint-up(lg) {   
        margin-top: -150px;
    }

    @include media-breakpoint-down(sm) {
        .row.items {
            padding-right: calc(var(--bs-gutter-x)*.5);
            padding-left: calc(var(--bs-gutter-x)*.5);
        }
        .row.posts-list {
            padding-right: calc(var(--bs-gutter-x)*.5);
            padding-left: calc(var(--bs-gutter-x)*.5);
        }
    }

    .card {                       
        .card-inner {
            box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            background-color: #fff;
            border-radius: $imageBorderRadius;    
            @include size(100%,100%);
            will-change: transform;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            .cta {                
                @include media-breakpoint-up(lg) {
                    // max-width: 206px;
                    // padding-left: 3.5rem;
                    // padding-right: 3.5rem;
                }                
            }            
            h3 { 
                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
            .image { 
                -webkit-mask-image: -webkit-radial-gradient(white, black);
                overflow: hidden;
                border-top-left-radius: $imageBorderRadius;    
                border-top-right-radius: $imageBorderRadius;    
                @include media-breakpoint-up(lg) {
                    border-radius: 0;
                    border-top-right-radius: $imageBorderRadius;    
                    border-bottom-right-radius: $imageBorderRadius;    
                }
            }
        }
        .card-image {
            background-size: cover;
            background-position: center;
            transition: all 600ms ease-in-out;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            border-top-left-radius: $imageBorderRadius;    
            border-top-right-radius: $imageBorderRadius;    
            @include media-breakpoint-up(lg) {
                border-radius: 0;
                border-top-right-radius: $imageBorderRadius;    
                border-bottom-right-radius: $imageBorderRadius;    
            }
        }
        &.first {       
            @include media-breakpoint-up(xl) {
                height: 697px;
            }
            .info {
                padding: 2rem;      
                .author-name {
                    font-weight: 700;
                    font-family: $font1;
                }          
                @include media-breakpoint-down(sm) {
                    height: calc(100% - 260px);
                }
            }
            .label {
                @include absolute(0,auto,auto,0,1);                
                @include max(200px,150px);
                background: linear-gradient(45deg, #F1665E 0%, #F1A63F 100%);
                color: #fff;
                transform: translate(-80px,-50px) rotate(-45deg);
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding: 6rem 4rem 1rem;        
            }
            .author {
                img {
                    max-width: 80px;
                    border-radius: 100%;
                }
            }
            .image {
                position: relative;
                overflow: hidden;                      

                img {
                    min-width: 100%;
                }

                @include media-breakpoint-only(md) {
                    height: 290px;
                }
                @include media-breakpoint-down(md) {
                    height: 260px;
                }
                @include media-breakpoint-up(lg) {
                    border-radius: 0 $imageBorderRadius $imageBorderRadius 0;
                    .card-image {
                        will-change: transform;
                    }
                }
            }     
            &:hover {
                @include media-breakpoint-up(lg) {
                    .image {
                        .card-image {
                            transform: scale(1.2);
                        }
                    }
                }
    
            }       
            
            @include media-breakpoint-up(lg) {
                .info {
                    width: 50%;
                }
                .image {
                    width: 50%;
                }
            }
            @include media-breakpoint-only(md) {
                .info {
                    .meta {
                        .details {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .info {
                    width: 100%;
                    padding: 1rem;
                    .details {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .meta {
                        align-items: flex-start;
                    }            
                }
                .image {
                    width: 100%;
                }
            }
        }
        &:not(.first) {                        
            .info {
                padding: 30px 40px 40px;
                
                justify-content: space-between;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
            .image {
                height: 290px;
                overflow: hidden;
                border-radius: 0;
                border-top-right-radius: $imageBorderRadius;    
                border-top-left-radius: $imageBorderRadius;    
                
                img {
                    @include size(auto,auto);
                    @include max(150%,none);
                    @include min(100%,100%);
                }
            }      
            .card-image {
                @include media-breakpoint-up(lg) {
                    border-radius: 0;
                    border-top-right-radius: $imageBorderRadius;    
                    border-top-left-radius: $imageBorderRadius;    
                }
            } 
            &:hover {
                @include media-breakpoint-up(lg) {
                    .image {
                        .card-image {
                            transform: scale(1.2);
                        }
                    }
                }
    
            }     
            
            @include media-breakpoint-up(lg) {
                .card-inner {
                    height: 703px;
                }
                .image {
                    height: 290px;
                }
                .info {
                    height: calc(100% - 290px);
                }
            }
            @include media-breakpoint-down(md) {
                .info {                    
                    @include size(100%,340px);
                    padding: 1rem;
                    .details {
                        flex-direction: column;
                        align-items: flex-start;
                    }        
                    .meta {
                        align-items: flex-start;
                    }            
                }
                .image {                    
                    @include size(100%,260px);                    
                }
            }
            @include media-breakpoint-down(sm) {
                .card-inner {
                    height: 597px;
                }
                .image {
                    height: 260px;
                    img {
                        @include size(auto,auto);
                        @include max(none,100%);
                        @include min(100%,100%);
                    }
                }
                .info {
                    height: calc(100% - 260px);
                }
            }
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            .card-inner {
                padding-bottom: 20px;
            }
        }
    }

    .load-more.invert {
        color: $color1 !important;
        border-color: $color1 !important;
        &:hover {
            color: #fff !important;
            background-color: $color1;
        }
    }

}

