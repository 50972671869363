$debug: true;
$debug: false;

// fonts

$font1: 'Roboto Slab', serif;
$font2: 'Roboto', serif;

// colors

$imageBorderRadius: 24px;

// colors

$color1: #4B5D81;
$color2: #F1665E;
$color3: #FBBE6A;
$color4: #8B9DC1;
$color5: #e5e5e5;

