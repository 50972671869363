section.numbers-1 {
    &::before {
        content: '';
        position: absolute;
        inset: 0 auto auto 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: #232e43;
        opacity: 0.7;
        z-index: 1;
    }
    .container {
        z-index: 2;
        position: relative;
    }

    h2,
    p {
        color: #fff;
    }
    .item {
        // display: flex;
        // align-items: center;
        margin-bottom: rem-calc(55);
        .num-col {
            text-align: right;
            .number {
                color: $color3;
                font-size: 85px;
                line-height: 70px;
                font-weight: 800;
                font-family: $font1;
                padding-right: 1rem;
                word-break: keep-all;
            }
        }
        strong {
            font-family: $font1;
        }
    }
    @include media-breakpoint-up(lg) {
        .item {
            .num-col {
                max-width: 60%;
            }
        }
        td {
            padding-bottom: 50px;
        }
    }
    @include media-breakpoint-down(md) {
        .item {
            margin-bottom: 30px;
            display: block;
            td {
                display: block;
            }
            .num-col {
                text-align: left;
                .number {
                    margin-bottom: 0;
                    padding-bottom: 0.5rem;
                    font-size: 60px;
                }
            }
        }
    }
}
