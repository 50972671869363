section {
    &.icons-blocks-1 {
        &.background-dark {
            background-color: $color1;
            h2,
            h3,
            h4,
            p {
                color: #fff;
            }
            .cta {
                border-color: #fff;
                color: #fff;
                @extend .light;
            }
        }
        &.background-light {
            .slick-dots {
                li {
                    background: transparent;
                    &:after {
                        background-color: $color1;
                    }
                }
            }
        }
        .image-container { 
            
            @include media-breakpoint-up(lg) {
                height: 90px;
            }

            img {
                max-width: 88px;
            }

            @include media-breakpoint-down(md) {
                img {
                    max-width: 100%;
                }
            }
        }
        .icons {            

            .item {
                margin-bottom: 3rem;

                

                div {
                    &:first-of-type {
                        @include media-breakpoint-down(md) {
                            display: flex;
                            align-items: center;
                            width: 55px;
                            img {
                                width: auto;
                            }
                        }
                    }
                }

                .p1 {
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                .p2 {
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: flex;
                    margin-bottom: 2rem;

                    div {
                        &:nth-of-type(2) {
                            padding-left: 1rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                margin: 0 auto 2rem auto;
            }

            
        }
        article {
            padding-bottom: 4rem;

            @include media-breakpoint-down(md) {
                padding: 0 1rem 0rem;
            }

            .title {
                position: relative;
                padding: 6rem 9rem 6rem 9rem;
                height: 300px;
                margin-bottom: 2rem;
                color: #fff;
                z-index: 2;

                h3,
                h4,
                p {
                    z-index: 1;
                }
                .bg {
                    @include absolute(50%, auto, auto, 0, -1);
                    transform: translate(0, -50%);

                    @include media-breakpoint-down(md) {
                        transform: translate(0, -50%);
                        max-width: 60%;
                    }
                }
                .txt {
                    @include absolute(50%, auto, auto, 17%, 1);
                    transform: translate(0, -50%);
                    @include size(60%, auto);

                    @include media-breakpoint-down(md) {
                        left: 240px;
                    }
                    @include media-breakpoint-down(sm) {
                        left: 30%;
                    }
                }
                @include media-breakpoint-down(sm) {
                    height: 200px;
                    margin-bottom: 0;
                }
            }
            .description {
                padding-right: 4rem;
                color: #fff;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }
        }
    }
}
