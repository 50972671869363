section.centered-banner-1 {
   
    background-color: #f2f2f2;

    .inner {
        z-index: 1;
    }

    .image {
        @include absolute(50%,auto,auto,50%,0);
        transform: translate(-50%,-58%);
        @include size(auto,auto);
        @include max(80%,80%);
    }

    
   
}
