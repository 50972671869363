/**
*
* Typo
*
*/

p,
h1,
h2,
h3,
h4 {
    color: $color1;
    word-break: break-word;
}

h1,
h2,
h3,
h4,
h5 {
    font-size: 30px;
    line-height: 100%;
}

p,
.p {
    font-size: 18px;
    line-height: 30px;
    font-family: $font2;

    @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 26px;
    }
}

.p1 {
    font-size: 18px;
    line-height: 20px;
}

.p2 {
    font-size: 22px;
    line-height: 30px;
    font-family: $font1;
}

ol {
    font-family: $font2;
    color: $color1;
}

.color-white {
    color: white;
}

.h1 {
    font-size: 60px;
    line-height: 70px;
    font-weight: 800;
    @include media-breakpoint-down(xl) {
        font-size: 40px;
        line-height: 40px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 40px;
        line-height: 40px;
    }
    @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 40px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 40px;
        line-height: 50px;
    }
}

.h2 {
    font-size: 50px;
    // line-height: 70px;
    line-height: 120%;
    font-family: $font1;
    font-weight: 800;
    @include media-breakpoint-down(xl) {
        font-size: 30px;
        line-height: 120%;
    }
    @include media-breakpoint-down(lg) {
        font-size: 25px;
        line-height: 120%;
    }
    @include media-breakpoint-down(md) {
        font-size: 25px;
        line-height: 120%;
    }
    @include media-breakpoint-down(sm) {
        font-size: 25px;
        // line-height: 70px;
        line-height: 120%;
    }
}

.h3 {
    font-size: 35px;
    line-height: 45px;

    @include media-breakpoint-down(xl) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 30px;
    }
}

.h4 {
    font-size: 30px;
    line-height: 40px;
    @include media-breakpoint-down(xl) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(md) {
        font-size: 20px;
        line-height: 30px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 30px;
        @if $debug {
            color: red !important;
        }
    }
}
.font-1,
.font-roboto-slab {
    font-family: $font1;
}
.font-2,
.font-roboto {
    font-family: $font2;
}

.fw-800 {
    font-weight: 800;
}

.fw-700 {
    font-weight: 700;
}
