section.big-blocks-1 {
    background-color: $color1;
    h2,
    h3,
    h4,
    p {
        color: #fff;
        z-index: 1;
    }
    article {        
        .title {
            position: relative;
            padding: 6rem 0;
            @include size(100%, 300px);
            margin-bottom: 2rem;
            color: #fff;
            z-index: 2;
            h3,
            h4,
            p {
                z-index: 1;
            }
            .bg {
                @include absolute(50%, auto, auto, 0, -1);
                transform: translate(0, -50%);
                max-width: 320px;
                @include media-breakpoint-only(md) {
                    max-width: 30%;
                }
            }
            .txt {
                @include absolute(50%, auto, auto, 0, 1);
                transform: translate(0, -50%);
                @include size(60%, auto);                
            }
        }
        .description {
            padding-right: 4rem;
            color: #fff;
            z-index: 1;
        }
    }
    @include media-breakpoint-up(lg) {
        article {
            padding-bottom: 4rem;            
        }
    }
    @include media-breakpoint-only(md) {
        article {
            padding-bottom: 2rem;
            .title {
                height: 220px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .container {
            padding-bottom: 4rem;
        }
        article {
            padding: 0 1rem 3rem;
            .title {
                height: 220px;
                .bg {
                    transform: translate(-10%, -50%);
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        article {
            .title {
                .bg {
                    @include max(150px, none);
                    transform: translate(0, -50%);
                }
            }
        }
    }
}
