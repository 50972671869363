/**
*
* Customizable elements
*
*/
.cta {
  border-radius: var(--btn-border-radius);
}
form input,
textarea {
  border-radius: var(--form-border-radius);
}

:root {
  --breakpoint-sm: #{map-get($grid-breakpoints, "sm")};
  --breakpoint-md: #{map-get($grid-breakpoints, "md")};
  --breakpoint-lg: #{map-get($grid-breakpoints, "lg")};
  --breakpoint-xl: #{map-get($grid-breakpoints, "xl")};
  --breakpoint-xxl: #{map-get($grid-breakpoints, "xxl")};
}

.breakpoint {
  @include fixed(auto, 1rem, 3rem, auto, 1000);
  @include size(100px, auto);
  background-color: #46b450;
  color: #fff;
  text-align: center;
  padding: 0.3rem;
  p {
    display: none;
  }
  .xs {
    @include media-breakpoint-only(xs) {
      display: block;
    }
  }
  .sm {
    @include media-breakpoint-only(sm) {
      display: block;
    }
  }
  .md {
    @include media-breakpoint-only(md) {
      display: block;
    }
  }
  .lg {
    @include media-breakpoint-only(lg) {
      display: block;
    }
  }
  .xl {
    @include media-breakpoint-only(xl) {
      display: block;
    }
  }
  .xxl {
    @include media-breakpoint-up(xxl) {
      display: block;
    }
  }
}

@include media-breakpoint-down(sm) {
  section {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

/**
*
* Aside
*
*/

.aside-bar {
  @include fixed(0, 0, auto, auto, 50);
  @include size(50vw, 100vh);
  background-color: #ffffff;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.3);
  transition: all 350ms ease-in-out;
  transform: translate(100%, 0);
  padding: 2rem;
  overflow: auto;

  background-image: url("../img/aside-bg.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: auto;

  .row.main {
    height: 100%;
  }

  .aside-toggle {
    @include absolute(2rem, 15%, auto, auto, 10);
    cursor: pointer;
    color: #000;
  }
  &.show {
    transform: translate(0, 0);
  }

  @media only screen and (min-width: 1921px) {
    .aside-toggle {
      left: 45%;
      right: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-left: 100px;
  }
  @include media-breakpoint-only(md) {
    width: 60vw;
  }
  @include media-breakpoint-down(md) {
    width: 100vw;
    padding: 2rem 0rem 2rem 2rem;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
    .aside-toggle {
      top: 0rem;
      right: 1rem;
    }
    .mCSB_inside > .mCSB_container {
      margin-right: 0;
    }
  }
  .aside-toggle {
    // @include absolute(3rem, 15%, auto, auto, 10);
    @include fixed(2rem, 15%, auto, auto, 10);
    cursor: pointer;
    color: #000;
    background-color: #fff;
    @include size(35px, 35px);
    border-radius: 100%;
  }
  &.show {
    transform: translate(0, 0);
  }

  form {
    input[type="checkbox"] + .wpcf7-list-item-label:before {
      border-color: $color1;
    }

    input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
      color: $color1;
    }
    label {
      max-width: 320px;
    }
    a {
      color: $color1;
    }
  }
}

.aside-social {
  @include media-breakpoint-up(lg) {
    @include fixed(50%, 2rem, auto, auto, 40);
    transform: translate(0, -50%);
    transition: all 250ms ease-in-out;

    // mix-blend-mode: screen;
    mix-blend-mode: multiply;
    img {
      transition: all 250ms ease-in-out;
    }
    ul {
      list-style: none;
    }
    &.dark {
      ul {
        img {
          filter: brightness(100);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    li {
      &:hover {
        img {
          animation-name: stretch;
          animation-duration: 500ms;
          animation-iteration-count: 1;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    right: 1rem;
    transform: translate(150%, -50%);
    .lang-switch {
      a {
        text-transform: uppercase;
        color: $color1;
        text-decoration: none;
        font-family: $font2;
        font-weight: bolder;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
@include media-breakpoint-down(lg) {
  body.mobile-nav {
    .aside-social {
      transform: translate(0, -50%);
    }
  }

  .hamburger {
    z-index: 100;
  }
  .nav-container {
    z-index: 99 !important;
  }
}

/**
*
* Post
*
*/

.post-title {
}

.post-content {
  &.blog-post {
    margin-bottom: 80px;
  }
  ul {
    padding-left: 1.4rem;
    list-style-image: url(../img/li.svg);
    li {
      color: $color1;
      @extend .p;
      padding-left: 1rem;
    }
  }
  .container:not(.main) {
    padding-left: 0;
    padding-right: 0;
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color4;
  div,
  span {
    display: inline-flex;
    align-items: center;
    color: $color4;
    font-family: $font2;
    font-size: 14px;
  }
}

.sidebar {
  form {
    input {
      color: #fff;
    }
    input::placeholder,
    textarea::placeholder {
      color: #fff;
      opacity: 1; /* Firefox */
    }
    label {
      color: #fff;
    }
  }

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 100px;
  }
}

.title {
  position: relative;
  display: inline-block;
  .icon {
    @include absolute(0, 0, auto, auto, 1);
    transform: translate(200%, -25%);
  }
}

.author-bio {
  background-color: #f2f2f2;
  border-radius: 16px;
  overflow: hidden;
  padding: 40px 34px;
  .author {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      p:nth-of-type(1) {
        font-family: $font1;
      }
      p:nth-of-type(2) {
        font-family: $font2;
      }
    }
    img {
      border-radius: 100%;
      @include size(60px, 60px);
    }
  }
}

.related-posts {
  article {
    margin-bottom: rem-calc(40);
    background-color: #fff;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
    border-radius: $imageBorderRadius;
    overflow: hidden;
    height: 100%;
    margin-bottom: 1rem;

    .author {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      div {
        p:nth-of-type(1) {
          font-family: $font1;
        }
        p:nth-of-type(2) {
          font-family: $font2;
        }
      }
      img {
        border-radius: 100%;
      }
    }

    .col-img {
      position: relative;
      overflow: hidden;
      img {
        @include absolute(50%, auto, auto, 50%, 1);
        @include size(100%, auto);
        @include max(none, none);
        @include min(100%, 100%);
        transform: translate(-50%, -50%);
        transition: all 250ms ease-in-out;
      }
      &.top {
        @include size(100%, 290px);
      }
    }

    .image {
      @include size(100%, 100%);
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      border-top-left-radius: $imageBorderRadius;
      border-top-right-radius: $imageBorderRadius;
      background-size: cover;
      .card-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
      }
      @include media-breakpoint-up(lg) {
        border-radius: 0;
        border-top-right-radius: $imageBorderRadius;
        border-top-left-radius: $imageBorderRadius;
      }
    }

    h3 {
      a {
        color: inherit;
        text-decoration: none;
      }
    }

    &:hover {
      .col-img {
        img {
          transform: translate(-50%, -50%) scale(1.025);
        }
      }
    }

    .col-txt {
      padding: 40px;
      position: relative;
      .cta {
        margin-top: rem-calc(100);
      }
      .label {
        @include absolute(0, auto, auto, 0, 1);
        @include max(200px, 150px);
        background: linear-gradient(45deg, #f1665e 0%, #f1a63f 100%);
        color: #fff;
        transform: translate(-80px, -50px) rotate(-45deg);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding: 6rem 4rem 1rem;
      }
      @include media-breakpoint-down(md) {
        padding: 20px;
      }
    }
  }
}

/**
*
* Forms
*
*/

select,
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: var(--form-border-radius);
  border: 1px solid $color1;
  outline: none !important;
  color: $color1;
  // margin-bottom: 20px;

  font-family: $font2;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;

  max-width: 545px;

  &.v-blue {
    color: white;
    border: 1px solid white;
    background-color: $color1;
    border-radius: var(--form-border-radius) !important;
  }
}
input {
  font-size: 18px;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  max-width: 88%;
  padding-top: 0;

  &.v-blue {
    color: white;
    border-bottom: 1px solid white;
  }
}
input::placeholder,
textarea::placeholder {
  font-size: 16px;
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
textarea {
  padding: 1rem;
  resize: none;
  height: 130px;
  line-height: 22px;
}

select,
input,
textarea {
  &.inline {
    width: auto;
    min-width: 320px;
    display: inline-block;
  }
}

select {
  position: relative;
  padding-right: 2.5rem;
  padding: 0.3rem 1rem 0.3rem 1.2rem;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  background: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.109691 1.17054C-0.194896 0.657079 0.170675 0 0.765185 0L7.23482 0C7.82933 0 8.1949 0.657079 7.89031 1.17054L4.65483 6.6254C4.35791 7.12537 3.64143 7.12436 3.34517 6.6254L0.109691 1.17054Z' fill='%234B5D81'/%3E%3C/svg%3E")
    no-repeat 93% center;

  option {
    font-family: $font2;
    font-size: 16px;
    font-weight: 400;
  }

  &.v-blue {
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOCA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC4xMDk2OTEgMS4xNzA1NEMtMC4xOTQ4OTYgMC42NTcwNzkgMC4xNzA2NzUgMCAwLjc2NTE4NSAwTDcuMjM0ODIgMEM3LjgyOTMzIDAgOC4xOTQ5IDAuNjU3MDc5IDcuODkwMzEgMS4xNzA1NEw0LjY1NDgzIDYuNjI1NEM0LjM1NzkxIDcuMTI1MzcgMy42NDE0MyA3LjEyNDM2IDMuMzQ1MTcgNi42MjU0TDAuMTA5NjkxIDEuMTcwNTRaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K")
      no-repeat 96% center;

    option {
      color: white;
      background-color: $color1;
    }
  }

  // &:after {
  //     content: '';
  //     display: block;
  //     @include absolute(50%, 1rem, auto, auto, 1);
  //     transform: translate(0, -50%) rotate(-135deg);
  //     @include size(15px, 15px);
  //     border-top: 2px solid $color1;
  //     border-right: 2px solid $color1;
  // }
}

.input-group {
  .required {
    color: white;
    position: relative;
    right: 0px;
    top: -2px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

// custom checkbox
.checkbox-container_ {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 0px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 26px;
  font-family: $font2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: white;
  }
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkmark {
      background-color: transparent;
    }
    /* Show the checkmark when checked */

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid white;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Style the checkmark/indicator */
    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: transparent;
  }
}
.checkbox-container {
  // display: inline;
  .wpcf7-form-control-wrap {
    display: none;
  }
}

.form-switch {
  // max-width: 545px;

  .form-check-input {
    margin-top: 2px;
    height: 1.08em;
    width: 2.3em;
    border: 0;
    border-radius: 0.5em;
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
    background-size: 17px;
    background-position: 2px 1px;

    &:checked {
      background-color: $color2;
      border-color: $color2;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
      background-position: calc(100% - 2px) 1px;
    }
    &:focus {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
    }
  }
}
.switch {
  margin-top: 2px;
  // height: 1.08em;
  // width: 2.3em;
  @include size(43px, 23px);
  border-radius: 1rem;
  cursor: pointer;
  border: 0;
  // border-radius: 0.5em;
  background-color: rgba(255, 255, 255, 0.6);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
  background-size: 22px;
  background-position: 0 0;
  background-repeat: no-repeat;

  &.checked {
    background-color: $color2;
    border-color: $color2;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
    background-position: 100% 0;
  }
  &:focus {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.5C17 13.1922 13.1922 17 8.5 17C3.80782 17 0 13.1922 0 8.5C0 3.80782 3.80219 0 8.5 0C13.1978 0 17 3.80782 17 8.5Z' fill='white'/%3E%3C/svg%3E");
  }

  @include media-breakpoint-down(sm) {
    // @include size(s)
  }
}

form {
  small {
    font-size: 14px;
    font-family: $font2;
  }
}

@include media-breakpoint-down(sm) {
  select {
    display: block !important;
    // width: 100% !important;
  }
}

/**
*
* Forms
*
*/

form {
  max-width: calc(#{map-get($grid-breakpoints, "lg")} / 2);
  .codedropz-upload-handler,
  input[type="text"],
  input[type="email"],
  label,
  select,
  textarea {
    // width: 100%;
    width: 320px;
    font-family: $font2;
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  input::placeholder,
  textarea::placeholder {
    color: $color1;
    font-size: 1rem;
  }
  .asterisk {
    transform: translateY(-10px);
    display: inline-block;
    opacity: 0.6;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    top: 0;
    right: -10px;
  }
  label {
    color: $color1;
  }
  .legal {
    br {
      // display: none;
    }
  }
  .wpcf7-list-item {
    label {
      display: inline-block;
      width: auto;
    }
  }
  .wpcf7-list-item-label {
    font-size: 14px;
    margin-left: 0.5rem;
    padding-left: 35px;
    display: inline-block;
    position: relative;
    br {
      display: none;
    }
  }
  .wpcf7-not-valid-tip,
  .has-error-msg {
    font-family: $font1;
  }
  .show-more {
    color: $color1;
    font-size: 14px;
    font-family: $font2;
    font-weight: 700;
  }
  .more {
    font-size: 10px;
    line-height: 100%;
    font-family: $font2;
    margin-bottom: 1rem;
    br {
      display: block;
    }
  }
  .wpcf7-list-item {
    margin-left: 0;
  }
  .wpcf7-not-valid-tip,
  .has-error-msg {
    font-size: 10px;
    margin-top: 5px;
  }
  .wpcf7-response-output {
    margin-left: 1rem !important;
    display: inline-block;
    border-radius: var(--form-border-radius);
    width: auto;
    font-family: $font2;
    font-size: 14px;
    color: $color2;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .codedropz-upload-handler {
    border-radius: var(--form-border-radius);
    border: 0;
    background-color: #f2f2f2;
    color: $color1;
    .dnd-upload-counter {
      display: none;
    }
    .codedropz-btn-wrap {
      a {
        color: $color1;
        font-size: 10px;
      }
    }
    .codedropz-upload-inner {
      span {
        display: none;
      }
    }
  }
  .dnd-upload-status {
    width: 320px;
    padding-bottom: 1rem;
    .dnd-upload-image {
      display: none;
    }
    .dnd-upload-details {
      padding-left: 0;
      .name {
        display: none;
      }
      .remove-file {
        top: 4px;
      }
    }
  }
  small {
    color: $color1;
    font-size: 14px;
    display: block;
    margin-top: 1.6rem;
  }

  .wpcf7-acceptance {
    display: inline-flex;
    align-items: center;
  }

  input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    @include size(0px, 0px);
  }

  input[type="checkbox"] + .wpcf7-list-item-label:before {
    // content: "\2713";
    content: "";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    @include size(25px, 25px);
    background: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    color: transparent;
    @include absolute(-3px, auto, auto, 0, 1);
  }

  input[type="checkbox"]:checked + .wpcf7-list-item-label:after {
    // content: "\2713";
    content: "";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    background: transparent;
    @include absolute(50%, auto, auto, 0, 1);
    @include size(15px, 8px);
    border-left: 3px solid $color1;
    border-bottom: 3px solid $color1;
    transform: translate(32%, 7px) rotate(-45deg);
    @include absolute(-3px, auto, auto, 0, 1);
  }

  input[type="checkbox"]:checked + .wpcf7-list-item-label:before {
    color: #fff;
  }

  .wpcf7-list-item {
    display: flex;
    align-items: center;
    .wpcf7-list-item-label {
      margin-left: 0;
    }
  }

  .legal-note {
    display: none;
    &.show {
      display: block;
      margin-left: 35px;
      margin-top: 0.5rem;
    }

    a {
      color: #fff;
    }
  }
  .wpcf7-response-output {
  }
}

.wpcf7 form.sent .wpcf7-response-output {
  background-color: #46b450;
  color: #fff;
  max-width: 100%;
  margin-left: 0 !important;
}
.wpcf7-form {
  .col-12 {
    margin-bottom: 20px;
    max-width: 95%;
  }
}

/**
*
* Homepage
*
*/

body.home {
  section.text-block-1 {
    @include media-breakpoint-up(lg) {
      background-image: url("../img/text-block-1-bg.svg");
      background-repeat: no-repeat;
      background-position: 0 100%;
    }
  }
}

/**
*
* Select inputs
*
*/
aside,
.contact,
.posts-1,
.posts-2,
.jobs-list {
  .select2-container {
    display: inline-block;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      margin-right: 1rem;
    }
    .select2-selection {
      position: relative;
      height: auto !important;
      border-radius: var(--form-border-radius);
      border: 1px solid $color1;
      .select2-selection__rendered {
        // padding: 8px 20px;
        padding: 0.35rem 1rem;
        color: $color1;
        font-family: $font2;
      }
    }
    .select2-container--open .select2-dropdown--below {
      border-bottom-left-radius: var(--form-border-radius);
      border-bottom-right-radius: var(--form-border-radius);
    }
    .select2-results__options {
      li {
        &:last-of-type {
          border-bottom-left-radius: var(--form-border-radius);
          border-bottom-right-radius: var(--form-border-radius);
        }
      }
    }
  }
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: $color4 !important;
}
.select2-results__option {
  color: $color1;
  font-family: $font2;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  @include absolute(50% !important, 0.75rem !important, auto, auto, 1);
  transform: translate(0, -50%);
  b {
    border-color: $color1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  }
}

.filters-label {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

aside {
  .select2-container {
    margin-bottom: 0;
    max-width: 100%;
  }
  input[type="email"] {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-breakpoint-up(md) {
    .wpcf7-form-control-wrap {
      position: relative;
      width: 320px;
      display: block;
    }
  }

  .wpcf7-response-output {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 320px;
  }
}

.reset_filters {
  cursor: pointer;
}

.contact {
  .select2-container--default .select2-selection--single {
    background: transparent;
    border-color: #fff;
  }
  .select2-container .select2-selection .select2-selection__rendered {
    color: #fff;
  }
  .select2-selection__arrow b {
    border-color: #fff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
  }
  .select2 {
    margin-bottom: 0;
  }
}

/**
*
* Global
*
*/

body {
  overflow-x: hidden;
  font-family: $font1;
  background-color: #fff;
  height: 100%;
  &:not(.home) {
    padding-top: 135px;
  }
  .overlay {
    display: none;
  }

  &.has-overlay {
    .overlay {
      display: block;
      content: "";
      @include fixed(0, 0, 0, 0, 11);
      @include size(100%, 100%);
      background-color: $color1;
      opacity: 0.8;
      @include media-breakpoint-down(xl) {
        z-index: 9;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &:not(.home) {
      padding-top: 90px;
    }
  }
}

section {
  position: relative;
  height: auto;

  .scroll-down {
    content: url("../img/scroll-down.svg");
    @include absolute(auto, auto, 80px, 50%, 1);
    transform: translateX(-50%);
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      bottom: 30px;
    }
  }

  &.inner-section {
    height: auto;
    .scroll-down {
      display: none;
    }
  }

  @media (orientation: horizontal) and (max-height: 670px) {
    &.fp-section .container {
      padding-top: 200px;
    }
  }

  @include media-breakpoint-up(lg) {
    &.fp-overflow {
      overflow-y: auto;
    }
    &.fp-section .container {
      // padding-top: 200px;
    }
  }
  // @include media-breakpoint-down(md) {
  //     .container {
  //         overflow-x: hidden;
  //     }
  // }
}

@supports (-webkit-touch-callout: none) {
  html {
    overflow-x: hidden;
  }
}

// #main-container {
//     margin-bottom: 50px;
// }

select.filter_posts {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
select.s2 {
}

.no-scroll {
  overflow: hidden;
}

.mobile-swipe {
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    &.swipe-flex {
      display: flex;
    }
  }
}

.fp-watermark {
  display: none !important;
}

/**
*
* Error
*
*/

section.error-404 {
  padding-top: 20vh;
  padding-bottom: 20vh;
  .h0 {
    font-size: 100px;
  }
}

/**
*
* Scrollbars
*
*/

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: $color1 !important;
}

/**
*
* Buttons
*
*/

.cta {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.78rem 2rem;
  border: 2px solid $color2;
  font-family: $font2;
  font-size: 14px;
  font-weight: 700;
  transition: all 150ms ease-in-out;
  text-align: center;
  // width: 100%;
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 0.48rem 2rem;
  }

  &:hover {
    text-decoration: none;
  }
  &.primary {
    background-color: $color2;
    color: #fff;
    &:hover {
      background: transparent;
      color: $color2;
    }
  }
  &.secondary {
    border: 2px solid $color1;
    color: $color1;
    &:hover {
      background-color: $color1;
      color: #fff;
    }
    &.light {
      color: #fff;
      border-color: #fff;
      &:hover {
        background-color: #fff;
        color: $color1;
      }
    }
  }
  &.transparent {
    background-color: transparent;
    border-color: white;
    color: #fff;
    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}

.link {
  color: $color1;
  &:hover {
    color: $color2;
  }
}

.close-icon {
  @include size(27px, 27px);
  &:before,
  &:after {
    content: "";
    @include size(27px, 3px);
    background-color: $color1;
    @include absolute(50%, auto, auto, 50%, 1);
    border-radius: 10px;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

/**
*
* Slideshow
*
*/

.slider-full {
  .slick-dots {
    @include absolute(auto, auto, 0, 0, 1);
    list-style: none;
    padding-left: 1rem;
    margin-bottom: 0;
    li {
      position: relative;
      display: inline-block;
      @include size(34px, 20px);
      margin-right: 1rem;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      button {
        visibility: hidden;
      }
      &:after {
        @include absolute(auto, auto, 0, 0, 1);
        content: "";
        display: flex;
        @include size(34px, 4px);
        background-color: #fff;
        border-radius: 100px;
        // transform: translateY(4px);
        opacity: 0.5;
        transition: all 250ms ease-in-out;
      }
      &.slick-active {
        @include size(93px, 20px);
        &:after {
          @include size(93px, 8px);
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    @include media-breakpoint-down(md) {
      left: 0;
      padding-left: 1rem;
    }
  }
  ._slick-dots {
    @include absolute(auto, auto, 80px, 50%, 1);
    transform: translate(-50%, 0);
    list-style: none;
    opacity: 0.5;
    margin: 0;
    li {
      display: inline-block;
      @include size(29px, 29px);
      border: 1px solid #fff;
      border-radius: 100%;
      background: transparent;
      margin: 0 10px;
      cursor: pointer;
      button {
        visibility: hidden;
      }
      &.slick-active {
        background-color: #fff;
      }
    }
  }
  .slick-arrow {
    @include absolute(50%, auto, auto, 50%, 1);
    @include size(29px, 29px);
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    cursor: pointer;
    &.slick-prev {
      transform: translate(calc(#{map-get($grid-breakpoints, "xl")} / -2), -50%)
        rotate(-135deg);
    }
    &.slick-next {
      transform: translate(calc(#{map-get($grid-breakpoints, "xl")} / 2), -50%)
        rotate(45deg);
    }
  }
}

.slick-disabled {
  opacity: 0;
}

.slider-dots {
  .slick-slide {
    @include media-breakpoint-down(sm) {
      margin-bottom: 6rem;
    }
  }
  .slick-dots {
    @include absolute(auto, auto, 0, 0, 1);
    list-style: none;
    padding-left: 1rem;
    li {
      position: relative;
      display: inline-block;
      @include size(34px, 20px);
      margin-right: 1rem;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      button {
        visibility: hidden;
      }
      &:after {
        @include absolute(auto, auto, 0, 0, 1);
        content: "";
        display: flex;
        @include size(34px, 4px);
        background-color: #fff;
        border-radius: 100px;
        // transform: translateY(4px);
        opacity: 0.5;
        transition: all 250ms ease-in-out;
      }
      &.slick-active {
        @include size(93px, 20px);
        &:after {
          @include size(93px, 8px);
          transform: translateY(0);
          opacity: 1;
        }
      }
      &:only-child {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      left: 0;
      padding-left: 1rem;
    }
  }

  &.dark-bg {
    li {
      // background-color: #fff;
    }
  }
  &.slider-gallery {
    .slick-dots {
      bottom: 4rem;
    }
  }
}

/**
*
* Temp
*
*/

/**
*
* Loader icon aka kręcioł
*
*/

$loader_color: $color1;

.loader {
  display: block;
  margin: 3rem auto 5rem;
  border: 12px solid #f3f3f3;
  border-top: 12px solid $color2;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $loader_color;
  color: $loader_color;
  box-shadow: 9999px 0 0 -5px $loader_color;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $loader_color;
  color: $loader_color;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px $loader_color;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
  left: 0;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px $loader_color;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.dot-pulse.invert {
  animation: dotPulse_invert 1.5s infinite linear;
}
.dot-pulse.invert:before {
  animation: dotPulseBefore_invert 1.5s infinite linear;
}
.dot-pulse.invert:after {
  animation: dotPulseAfter_invert 1.5s infinite linear;
}

@include media-breakpoint-down(sm) {
  .dot-pulse.mobile-invert {
    animation: dotPulse_invert 1.5s infinite linear;
  }
  .dot-pulse.mobile-invert:before {
    animation: dotPulseBefore_invert 1.5s infinite linear;
  }
  .dot-pulse.mobile-invert:after {
    animation: dotPulseAfter_invert 1.5s infinite linear;
  }
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px $loader_color;
  }
  30% {
    box-shadow: 9984px 0 0 2px $loader_color;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $loader_color;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px $loader_color;
  }
  30% {
    box-shadow: 9999px 0 0 2px $loader_color;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $loader_color;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px $loader_color;
  }
  30% {
    box-shadow: 10014px 0 0 2px $loader_color;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px $loader_color;
  }
}

@keyframes dotPulseBefore_invert {
  0% {
    box-shadow: 9984px 0 0 -5px $color1;
  }
  30% {
    box-shadow: 9984px 0 0 2px $color1;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $color1;
  }
}

@keyframes dotPulse_invert {
  0% {
    box-shadow: 9999px 0 0 -5px $color1;
  }
  30% {
    box-shadow: 9999px 0 0 2px $color1;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $color1;
  }
}

@keyframes dotPulseAfter_invert {
  0% {
    box-shadow: 10014px 0 0 -5px $color1;
  }
  30% {
    box-shadow: 10014px 0 0 2px $color1;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px $color1;
  }
}

/**
*
* Preloader
*
*/

#preloader {
  @include fixed(0, auto, auto, 0, 150);
  @include size(100vw, 100vh);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .label {
    font-family: $font1;
    font-weight: bold;
  }
  .loader-box {
    position: relative;
    overflow: hidden;
    // @include size(173px, 182px);
    @include size(87px, 91px);
    max-height: 90px;

    .shape {
      @include absolute(50%, auto, auto, 50%, 2);
      transform: translate(-50%, -50%);
      // @include size(173px, 182px);
      @include size(87px, 91px);
      @include max(none, none);
    }

    .progress-bar {
      @include absolute(auto, auto, 2px, 50%, 1);
      @include size(80%, 0%);
      transform: translate(-50%, 0);
      background-color: #f1665e;
      transition: all 100ms ease-in-out;
    }

    .bg {
      @include absolute(50%, auto, 0, 50%, 0);
      @include size(80%, 80%);
      transform: translate(-50%, -50%);
      background-color: #dde3ee;
    }
  }
}

/**
*
* Images & Video
*
*/

img {
  @include size(auto, auto);
  @include max(100%, 100%);
}

video {
  @include size(auto, auto);
  @include max(100%, none);
}

/**
*
* Admin
*
*/

#wpadminbar {
  @include media-breakpoint-only(md) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
  }
}

.newsletter-box {
  background-color: $color1;
  background-image: url("../img/sidebar-bg-1.svg");
  background-position: center 25px;
  background-size: 85%;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;
  padding: 35px;

  h3,
  h4,
  p {
    color: #fff;
    margin-bottom: 0;
  }
  .wpcf7-email {
    border-bottom: 1px solid white;
    border-radius: 0;
    padding-left: 0;
    color: white;
  }
  input::placeholder {
    // color: white;
  }
  .checkbox {
    input {
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      line-height: 30px;
      color: white;
      font-family: $font2;

      a {
        color: white;
      }
    }
  }
  .show-more {
    color: white;
  }
  .wpcf7-list-item {
    margin: 0;
  }
  .wpcf7-not-valid-tip {
    color: #e94a4a;
  }
  .btn-submit {
    input {
      text-align: center;
      font-family: $font2;
      display: block;
      width: 100%;
      border: 1px solid white;
      border-radius: 12px;
      height: 50px;
      margin-top: 3px;
      font-size: 14px;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
    }
  }
}

.show-more,
.show-less {
  display: inline-block;
}
.more {
  display: none;
}

.font-roboto-slab {
  font-family: $font1;
}
.font-roboto {
  font-family: $font2;
}

input[type="number"] {
  width: 100%;
  padding: 0.25rem 1rem;
  border-radius: var(--form-border-radius);
  border: 1px solid #4b5d81;
  outline: none !important;
  color: #4b5d81;
  font-family: Roboto, serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  max-width: 545px;
}

section.contact form input[type="number"] {
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  max-width: 100%;
  background: rgba(0, 0, 0, 0);
  margin-bottom: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.UE_logo {
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: 80px;
  right: 27px;
  max-width: 220px;
  @include media-breakpoint-up(lg) {
    max-width: 300px;
    bottom: 90px;
    top: auto;
    right: 65px;
  }
}

.m4u-pagination a,
.m4u-pagination span {
  border-radius: var(--btn-border-radius);
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 10px;
  margin: 5px;
  font-family: $font2;
  font-size: 14px;
  font-weight: 700;
  transition: all 150ms ease-in-out;
  text-align: center;

  border: 2px solid $color1;
  color: $color1;
  &:hover {
    background-color: $color1;
    color: #fff;
  }
  &.light {
    color: #fff;
    border-color: #fff;
    &:hover {
      background-color: #fff;
      color: $color1;
    }
  }
}

.m4u-pagination span {
  &.current {
    cursor: default;
    background-color: $color1;
    color: #fff;
  }
  &.dots {
    cursor: default;
    &:hover {
      background-color: transparent;
      color: $color1;
    }
  }
}
