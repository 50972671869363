section.subpage-title {

    @include media-breakpoint-up(lg) {
        min-height: 440px;
    }

    @include media-breakpoint-down(md) {
        min-height: unset;
    }

    .h1 {
        margin-bottom: 34px;

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }
    .p {
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;

        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 30px;
        }
    }
}
