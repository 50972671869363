section.banner-1 {
    background-color: $color3;
    h2,
    p {
        color: $color1;
    }

    @include media-breakpoint-down(sm) {
        .img {
            text-align: center;
        }
        .h3 {
            font-size: 25px;
            line-height: 35px;
        }
    }
}
